import axios from "axios"

const token = process.env.REACT_APP_COOKIE_AUTH_KEY

export const APICREDHABITAR = axios.create({
  baseURL: 'https://api.simulador.portalcredihabitar.com.br/calcular_parcela'
})

export const APICREDHABITARLEADS = axios.create({
  baseURL: 'https://api.simulador.portalcredihabitar.com.br/send_email'
})

// export const APICREDHABITAR = axios.create({
//   baseURL: 'http://127.0.0.1:5000/calcular_parcela'
// })

// export const APICREDHABITARLEADS = axios.create({
//   baseURL: 'http://127.0.0.1:5000/send_email'
// })
