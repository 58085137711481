import Cookies from 'js-cookie'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ModalUserInfo } from '../common/components/modalUserInfor'
import { ProcessContext } from '../context/processContext'
import { SimulationContext } from '../context/simulationContext'
import { Col, Button } from 'react-bootstrap'
import { MinInstallment } from '../common/components/minimunInstallment'
import { UserContext } from '../context/userContext'
import logoCh from '../../img/logo-ch.svg'
import fundoHome from '../../img/fundo-home.png'

export const Process = () => {
  const { processList } = useContext(ProcessContext)
  const { simulationFI, setSimulationFI, simulationCGI, setSimulationCGI } =
    useContext(SimulationContext)

  const { user, setUser } = useContext(UserContext)

  const [showModalUser, setShowModalUser] = useState(false)

  const history = useHistory()

  const openModal = () => {
    setShowModalUser(true)
  }
  const extraUrl = window.location.search

  const backgroundStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  };

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${fundoHome})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    opacity: 0.1
  };

  return (
    <main className="container-fluid">
      <div style={backgroundStyle}>
        <div style={overlayStyle}></div>
        <div className="row my-5 h-100">
          <div className="col-md-6 p-3 p-xl-5 rounded-3 w-50 mx-auto position-relative top-50 translate-middle-y text-center media-450">
            <div>
              <img
                className="mb-5 log"
                src={logoCh}
                alt='Credihabitar plataforma de financiamento imobiliario'
                height={40}
                onClick={() => { history.push(`${extraUrl}`) }}
                style={{ cursor: "pointer" }}
              />
            </div>
            <h4 className="fs-3 font-weight-bold">
              Essa é a <span className="font-custom">menor parcela</span> que encontramos<br /> pra você.
            </h4>
            <div className="my-0 my-md-3">
              <img
                src="/img/simulador-md.png"
                alt=""
                className="img-fluid d-none d-md-inline"
              />
            </div>
            <div className="d-grid gap-2 d-md-flex justify-content-center">
              <Button
                size="lg"
                className="btn-custom rounded-pill py-3 px-5 my-5 my-md-0"
                onClick={openModal}
              >
                {processList?.length > 0 && processList[0].productType === 'aquisicao' ? 'Ver todas as simulações' : 'Tenho interesse'}
              </Button>
            </div>
          </div>
          <div className="col-md-6">
            <MinInstallment />
          </div>
          <div >
            <h5 className="text-center position-absolute bottom-0 start-50 translate-middle footer">
              As condições apresentadas são apenas uma simulação.<br />
              Crédito condicionado a aprovação do agente financeiro e envio de documentação pertinente.
            </h5>
          </div>
        </div>
      </div>
      {showModalUser && (
        <ModalUserInfo
          showModalUser={showModalUser}
          setShowModalUser={setShowModalUser}
          id={processList[0].pkHash}
          processList={processList}
          setUser={setUser}
          user={false}
          productType={processList[0].productType}
          parameters={extraUrl}
        />
      )}
    </main>
  )
}
