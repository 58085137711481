import snakecaseKeys from "snakecase-keys";
import Swal from "sweetalert2";
import { APICREDHABITARLEADS } from "../../../api";

export const onSubmitUserInfo = async (userInfo, history, id, processList, parameters, productType) => {
  let userData = {};
  let simulationData = {};

  let localSimulationFI = JSON.parse(localStorage.getItem("@SimulationFI"));
  let localSimulationCGI = JSON.parse(localStorage.getItem("@SimulationCGI"));

  userData.name = userInfo.name;
  userData.phonenumber = userInfo.phonenumber;
  userData.email = userInfo.email;

  userData.phonenumber = userData.phonenumber.replace(" ", "");
  if (localSimulationFI && processList[0].productType === "aquisicao") {
    simulationData = { ...localSimulationFI };
    simulationData.tpl_category_id = 1;
  } else {
    simulationData = { ...localSimulationCGI };
    simulationData.tpl_category_id = 2;
  }

  simulationData = snakecaseKeys(simulationData);

  return await APICREDHABITARLEADS.post("", { ...userData, ...simulationData }).then((res) => {
    Swal.fire({
      title: 'Obrigado!',
      text: 'As simulações foram enviadas para o seu email e um especialista entrará em contato em breve.',
      // text: 'Um consultor de crédito entrará em contato em breve.',
      icon: 'success',
      confirmButtonText: 'Fechar',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-lg btn-danger',
      },
    }).then((res) => {
      localStorage.removeItem('@SimulationFI')
      localStorage.removeItem('@SimulationCGI')
      localStorage.removeItem('@lastSimulation')
      history.push('/')
    })
    // if (productType === 'aquisicao') {
    //   history.push(`/process/${id}`);
    //   return localStorage.setItem("@UserCreated", true);
    // } else {
    //   Swal.fire({
    //     title: 'Obrigado!',
    //     text: 'As simulações foram enviadas para o seu email e um especialista entrará em contato em breve.',
    //     // text: 'Um consultor de crédito entrará em contato em breve.',
    //     icon: 'success',
    //     confirmButtonText: 'Fechar',
    //     buttonsStyling: false,
    //     customClass: {
    //       confirmButton: 'btn btn-lg btn-danger',
    //     },
    //   }).then((res) => {
    //     localStorage.removeItem('@SimulationFI')
    //     localStorage.removeItem('@SimulationCGI')
    //     localStorage.removeItem('@lastSimulation')
    //     history.push('/')
    //   })
    // }
  }).catch((err) => {
    return Swal.fire({
      icon: "error",
      text: "Ooops... Desculpe. Já avisamos nossos técnicos.",
      title: "Algo deu errado!",
    });
  });
};
