import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { formatMoney } from '../common/utils/format/formatMoney'
import { ProcessContext } from '../context/processContext'
import { BiArrowBack } from 'react-icons/bi'
import Swal from 'sweetalert2'

import { motion } from 'framer-motion'
import { Button, Col, Row } from 'react-bootstrap'

import { SimulationContext } from '../context/simulationContext'
import { appearAnimation } from '../common/animations/surger'
import Cookies from 'js-cookie'
import logoCh from '../../img/logo-ch.svg'
import bradesco from '../../img/bradesco.png'
import brb from '../../img/brb.png'
import itau from '../../img/itau.png'
import caixa from '../../img/caixa.png'
import santander from '../../img/santander.png'

const banksObj = {
  'bradesco': bradesco,
  'brb': brb,
  'caixa': caixa,
  'itau': itau,
  'santander': santander,
}

const handleClick = (e, history, setSimulationFI, setSimulationCGI) => {
  Swal.fire({
    title: 'Obrigado!',
    text: 'As simulações foram enviadas para o seu email e um especialista entrará em contato em breve.',
    // text: 'Um consultor de crédito entrará em contato em breve.',
    icon: 'success',
    confirmButtonText: 'Fechar',
    buttonsStyling: false,
    customClass: {
      confirmButton: 'btn btn-lg btn-danger',
    },
  }).then((res) => {
    localStorage.removeItem('@SimulationFI')
    localStorage.removeItem('@SimulationCGI')
    localStorage.removeItem('@lastSimulation')
    setSimulationFI({})
    setSimulationCGI({})
    history.push('/')
  })
}

export const ProcessDetail = () => {
  const { processList } = useContext(ProcessContext)
  const { simulationFI, setSimulationFI, setSimulationCGI, simulationCGI } =
    useContext(SimulationContext)
  const [smallerInstallment, setSmallerInstallment] = useState({})
  const history = useHistory()

  const backSimulation = () => {
    let simalationSaved = new Date()
    Cookies.set('simulation', simalationSaved, { expires: 1 / 48 })

    let localSimulationFI = JSON.parse(localStorage.getItem('@SimulationFI'))
    let localSimulationCGI = JSON.parse(localStorage.getItem('@SimulationCGI'))

    if (processList[0].productType === 'Aquisição') {
      setSimulationFI({ ...simulationFI, ...localSimulationFI })
    } else {
      setSimulationCGI({ ...simulationCGI, ...localSimulationCGI })
    }

    return history.push('/')
  }

  useEffect(() => {
    if (processList.length === 0) {
      history.push('/')
    }
    let minInstallment = processList[0] || {}
    processList.forEach((process) => {
      if (process.firstInstallment < minInstallment.firstInstallment) {
        minInstallment = process
      }
    })
    setSmallerInstallment(minInstallment)
  }, [])
  return (
    <>
      <motion.main {...appearAnimation} className="container my-5">
        <Row>
          <Col md="12" className='mb-5'>
            <img
              className="mb-5 log"
              src={logoCh}
              alt='Credihabitar plataforma de financiamento imobiliario'
              height={30}
              onClick={() => { history.push('/') }}
              style={{ cursor: "pointer" }}
            />
          </Col>
          <div className="col-lg-12 table-responsive">
            <table className="w-100 table">
              <thead>
                <tr>
                  <th className='h5'>Banco</th>
                  <th className='h5'>Primeira Parcela</th>
                  <th className='h5'>Ultima Parcela</th>
                  <th className='h5'>Renda mínima</th>
                  <th className='h5'>Taxa</th>
                  <th className='h5'>Prazo</th>
                </tr>
              </thead>
              <tbody>
                {processList?.map((process, key) => {
                  const formatMinimunIncome = formatMoney(process.minimumIncome)
                  const formatFirstIncome = formatMoney(process.firstInstallment)
                  const formatLastIncome = formatMoney(process.lastInstallment)
                  return (
                    <tr key={key}>
                      <th>
                        <figure>
                          <img
                            src={banksObj[process.bank.toLowerCase()]}
                          />
                        </figure>
                      </th>
                      <th>{formatFirstIncome}</th>
                      <th>{formatLastIncome}</th>
                      <th>{formatMinimunIncome}</th>
                      <th>{process.publicFee}% a.a. (CET)</th>
                      <th>{process.prazo}</th>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <Row className='justify-content-end mt-4'>
              <Col md="7" className='gap-2 d-flex'>
                <Button size="lg" variant="custom-2" className='rounded-pill w-50 p-3 btn-custon-2' onClick={backSimulation}>
                  <BiArrowBack color='white' />
                  <span className="ms-2">Nova simulação</span>
                </Button>
                <Button
                  size="lg"
                  variant="custom"
                  onClick={(e) =>
                    handleClick(e, history, setSimulationFI, setSimulationCGI)
                  }
                  className="rounded-pill p-3 w-50"
                >
                  Tenho interesse
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
      </motion.main>
    </>
  )
}
