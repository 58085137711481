import camelcaseKeysDeep from 'camelcase-keys-deep'
import { createContext, useEffect, useState } from 'react'
import { APIVALIDATION } from '../api'

export const ValidationContext = createContext({})

const ValidationProvider = ({ children }) => {
  const [validationFI, setValidationFI] = useState(null)
  const [validationCGI, setValidationCGI] = useState(null)


  useEffect(() => {
    // APIVALIDATION.get('').then(({data}) => {
      let data = [
        {
            "id": 1,
            "name": "Financiamento imobiliário",
            "show_on_simulator": true,
            "order": 1,
            "min_good_price": 90000.0,
            "max_good_price": 20000000.0,
            "max_loan_percent": 0.8,
            "min_time_months": 12,
            "max_time_months": 420,
            "min_loan_value": 60000.0,
            "min_age": 18,
            "max_age": 79,
            "slug": "aqu",
            "short_name": "Aquisição"
        },
        {
            "id": 2,
            "name": "Crédito com garantia de imóvel",
            "show_on_simulator": true,
            "order": 2,
            "min_good_price": 70000.0,
            "max_good_price": 20000000.0,
            "max_loan_percent": 0.6,
            "min_time_months": 36,
            "max_time_months": 240,
            "min_loan_value": 30000.0,
            "min_age": 18,
            "max_age": 79,
            "slug": "cgi",
            "short_name": "CGI"
        },
        {
            "id": 4,
            "name": "Crédito com garantia de veículo",
            "show_on_simulator": true,
            "order": 3,
            "min_good_price": 10000.0,
            "max_good_price": 500000.0,
            "max_loan_percent": 0.9,
            "min_time_months": 12,
            "max_time_months": 60,
            "min_loan_value": 1000.0,
            "min_age": 18,
            "max_age": 79,
            "slug": "cgv",
            "short_name": "CGV"
        }
    ]
      setValidationFI(camelcaseKeysDeep(data[0]))
      setValidationCGI(camelcaseKeysDeep(data[1]))
    // })
  }, [])
  
  return (
    <ValidationContext.Provider value={{ validationCGI, validationFI }}>
      {children}
    </ValidationContext.Provider>
  )
}
export default ValidationProvider
